import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button, CircularProgress,
    Divider,
    Grid,
    ListItem,
    Typography
} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import NavTabs from "../../../../lib/components/tabs/NavTabs";
import {ClinicalDataShowTabStyles} from "./clinicalDataShowTabStyles";
import {ClinicalDataProvider} from "../../../../resources/clinicalData/clinicalData/clinicalDataProvider";
import {ClinicalDataResource} from "../../../../resources/clinicalData/clinicalData/clinicalDataResource";
import {ClinicalData} from "../../../../resources/clinicalData/clinicalData/clinicalData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SourceFileDataProvider} from "../../../../resources/clinicalData/sourceFile/sourceFileDataProvider";
import {SourceFile} from "../../../../resources/clinicalData/sourceFile/sourceFile";
import {SourceFileResource} from "../../../../resources/clinicalData/sourceFile/sourceFileResource";
import {getDetailErrorCause} from "../../../../resources/messages";
import {FileType} from "../../sourceFile/upload/upload";

interface FileClinicalData {
    entity_id: string;
    entity_name: string;
    file_id: string;
    file_name: string;
    rows: FileClinicalDataRow[]
}

interface FileClinicalDataRow {
    variable_id: string;
    variable_name: string;
    value: string;
}

export function ClinicalDataShowTabView(): ReactElement {
    const classes = ClinicalDataShowTabStyles();
    const [data, setData] = useState<ClinicalData[]>([]);

    const [error, setError] = useState<number>();

    const navigate = useNavigate();
    const {id} = useParams();
    const [searchParams] = useSearchParams()


    const resource = ClinicalDataResource;

    const clinicalDataProvider = (resource.dataProvider as unknown as ClinicalDataProvider);
    const sourceFileDataProvider = (SourceFileResource.dataProvider as unknown as SourceFileDataProvider);

    useEffect(() => {
        initData(searchParams.get("clinical_study_id") || '');
    }, [resource])

    const initData = (clinicalStudyId: string) => {
        if (!id || !clinicalStudyId) return;
        clinicalDataProvider.getDetails(clinicalStudyId, id)
            .then((data) => {
                setData(data.data);
            }).catch((error) => {
            setError(error.response.status)
        });
    }

    const mainClinicalData = (): FileClinicalData[] => {
        return reduceToEntityClinicalData(data.filter(d => d.file_type === FileType.MAIN_CLINICAL));
    }

    const otherClinicalData = (): FileClinicalData[] => {
        return reduceToEntityClinicalData(data.filter(d => d.file_type === FileType.OTHER));
    }

    const reduceToEntityClinicalData = (data: ClinicalData[]): FileClinicalData[] => {
        return data.reduce((acc: FileClinicalData[], current: ClinicalData) => {
            const idx = acc.findIndex((d) => d.file_id === current.file_id);
            if (idx === -1) {
                acc.push({
                    entity_id: current.entity_id,
                    entity_name: current.entity_name,
                    file_name: current.file_name,
                    file_id: current.file_id,
                    rows: [{
                        value: current.value,
                        variable_name: current.variable_name,
                        variable_id: current.variable_id
                    }]
                });
                return [...acc, ]
            }else{
                acc[idx].rows.push({
                    variable_id: current.variable_id,
                    variable_name: current.variable_name,
                    value: current.value
                });
                return acc;
            }
        }, []);
    }

    const renderMainClinicalData = () => {
        const mainClinical = mainClinicalData()
        if (mainClinical.length!==1) return null;
        const entity = mainClinical[0];

        return (
            <>
                <Typography variant={"pageTitlePrefix"}>{entity.entity_name} | {entity.file_name}</Typography>
                <Grid container direction={"column"}>
                    <ListItem>
                        <Grid container direction={"row"}
                              className={classes.listContainer}>
                            <Grid item md={5.5} justifyContent={"space-evenly"}>
                                <Typography
                                    variant={"dataTitle"}><strong>{"Name"}</strong></Typography>
                            </Grid>
                            <Grid item md={5.5}>
                                <Typography variant={"dataTitle"}><strong>{"Value"}</strong></Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider/>
                    {entity.rows.map((row, idx) => (
                        <ListItem key={idx}>
                            <Grid container direction={"row"}
                                  className={classes.listContainer}>
                                <Grid item md={5.5}>
                                    <Typography
                                        variant={"dataTitle"}>{row.variable_name}</Typography>
                                </Grid>
                                <Grid item md={5.5}>
                                    <Typography>{row.value}</Typography>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </Grid>
            </>
        )
    }


    return (
        <>
            <Grid container className={classes.mainContainer}>
                <Button onClick={() => navigate(-1)}>
                    <KeyboardBackspaceIcon color={"primary"}></KeyboardBackspaceIcon>
                    <Typography variant={"backButtonText"}>Go back</Typography>
                </Button>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container direction={"row"} className={classes.titleContainer}>
                            <Grid item>
                                <Typography variant={"pageTitlePrefix"}>{resource?.label} / &nbsp;</Typography>
                                <Typography variant={"pageTitle"} className={classes.title}>paziente {id}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid marginTop={10}>
                    </Grid>
                    {
                        (error) &&
                        <Grid className={classes.errorContainer}>
                            <Typography variant={"dataTitle"} color={"error"}>
                                <strong>ATTENTION!</strong> {error ? getDetailErrorCause(error) : "Invalid patient or clinical study not selected."}
                            </Typography>
                        </Grid>
                    }
                    { data &&
                    <Grid item>
                        <NavTabs
                            tabs={[
                                {
                                    label: "Main clinicalData",
                                    children: (
                                               <div style={{
                                                   boxShadow: "0px 1px 50px rgba(0, 0, 0, 0.08)",
                                                   width: "100%"
                                               }}>
                                                   {renderMainClinicalData()}

                                               </div>
                                              )
                                },
                                {
                                    label: "Other",
                                    children: (
                                        <>
                                            {otherClinicalData().map((entity, idx) => (
                                                <Accordion key={idx}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon/>}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography variant={"pageTitlePrefix"}>{entity.entity_name} | {entity.file_name}</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container direction={"column"}>
                                                            <ListItem key={idx}>
                                                                <Grid container direction={"row"}
                                                                      className={classes.listContainer}>
                                                                    <Grid item md={5.5} justifyContent={"space-evenly"}>
                                                                        <Typography
                                                                            variant={"dataTitle"}><strong>{"Name"}</strong></Typography>
                                                                    </Grid>
                                                                    <Grid item md={5.5}>
                                                                        <Typography variant={"dataTitle"}><strong>{"Value"}</strong></Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </ListItem>
                                                            <Divider/>
                                                            {entity.rows.map((row, idx) => (
                                                                <ListItem key={idx}>
                                                                    <Grid container direction={"row"}
                                                                          className={classes.listContainer}>
                                                                        <Grid item md={5.5}>
                                                                            <Typography
                                                                                variant={"dataTitle"}>{row.variable_name}</Typography>
                                                                        </Grid>
                                                                        <Grid item md={5.5}>
                                                                            <Typography>{row.value}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </ListItem>
                                                            ))}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </>)
                                },
                                {
                                    label: "Metadata",
                                    children: (
                                        <>
                                            <Grid container direction={"row"} className={classes.listContainer}>
                                                <Grid item md={5.5}>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )
                                }
                            ]
                            }
                        ></NavTabs>
                    </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}
