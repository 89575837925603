import {ResourceConfig} from "../../lib/types/resource";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import Variable from "./variable";
import {VariablesDataProvider} from "./variablesDataProvider";
import GenericSearch from "../genericSearch";
import {StaticSelectMeta} from "../../lib/meta/select/staticSelect";
import {DefaultShowView} from "../../lib/views/crud/show/default";

export const VariablesResource: ResourceConfig<Variable, GenericSearch> = {
    id: "variables",
    label: "Variables",
    dataProvider: new VariablesDataProvider(),
    actions: [],
    edit: undefined,
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "description",
            label: "Description",
            meta: new SimpleTextMeta({})
        },
        {
            id: "data_type",
            label: "Data Type",
            meta: new StaticSelectMeta({
                label: 'Data Type',
                options: {
                    "string": "String",
                    "number": "Number",
                },
                listValuer: (data) => (data as Variable).data_type.toUpperCase()
            }),
        }
    ],
    list: {
        id: "variables",
        pageSize: 20,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}]
    },
    show: {
        component: <DefaultShowView previousPage={-1} id={"variables"} fields={[{id: "id"}, {id: "name"}, {id: "description"}, {id: "data_type"}]}/>,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}, {id: "data_type"}]
    },
    create: {
        pageSize: 5,
        fields: [
            {
                id: "name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {
                id: "description",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Description is required."
                    }
                },
            },
            {
                id: "data_type",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Data type is required."
                    },
                },
            }
        ]
    },
    description: "List of the variables",
    isDeletable: () => true,
    primaryKey: "id",
    groupId: "appManager"

}
