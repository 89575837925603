import {IAuthenticationProvider, IAuthorizationProvider} from "../lib/types/auth";
import {CustomAuthData, MiWebRefreshTokenPayload} from "./provider";
import jwtDecode from "jwt-decode";
import {Permissions} from "../resources/authorizations/permission/permissions";
import {Verb} from "../lib/context/AuthorizationContext";

interface PermissionsToken {
    permissions: string[]
}

export default class AuthorizationProvider implements IAuthorizationProvider {
    hasAccess(authenticationProvider: IAuthenticationProvider<CustomAuthData>, group: string, resource?: string, verb?: Verb): boolean {
        return true;
       // if (!authenticationProvider) {
       //      return false;
       //  }
       //  const authData = authenticationProvider.getAuthData()
       //  if (!authData.isAuthenticated()) {
       //      return false
       //  }
       //  const token = jwtDecode<PermissionsToken>(authData.accessToken || "")
       //  const permissions = token.permissions
       //  if (!Permissions[group]) {
       //      return true
       //  }
       //
       //  const groupResources = Object.keys(Permissions[group])
       //  if (resource) {
       //      if (verb) {
       //          for (let perm of Permissions[group][resource][verb]) {
       //              if (permissions.indexOf(perm) === -1) {
       //                  return false;
       //              }
       //          }
       //          return true;
       //      } else {
       //          if (!Permissions[group][resource]) {
       //              return false;
       //          }
       //          for (let perm of Permissions[group][resource].read) {
       //              if (permissions.indexOf(perm) === -1) {
       //                  return false;
       //              }
       //          }
       //          return true;
       //      }
       //  } else {
       //      for (let res of groupResources) {
       //          if (Permissions[group][res].read.length === 0) {
       //              return true;
       //          }
       //          for (let perm of Permissions[group][res].read) {
       //              if (permissions.indexOf(perm) !== -1) {
       //                  return true
       //              }
       //          }
       //      }
       //  }
        return false
    }

    getContractSigned(authenticationProvider: IAuthenticationProvider<any>): boolean {
        const authData = authenticationProvider.getAuthData()
        if (authData.accessToken === null) {
            return false;
        }
        const token = jwtDecode<MiWebRefreshTokenPayload>(authData.accessToken)
        return token.terms_and_conditions ? true : false;
    }

    getRoleId(authenticationProvider: IAuthenticationProvider<any>): string {
        const authData = authenticationProvider.getAuthData()
        if (authData.accessToken === null) {
            return "";
        }
        const token = jwtDecode<MiWebRefreshTokenPayload>(authData.accessToken)
        return token.group_id;
    }

    getRoleName(authenticationProvider: IAuthenticationProvider<CustomAuthData>): string {
        const authData = authenticationProvider.getAuthData()
        if (authData.accessToken === null) {
            return "";
        }
        const token = jwtDecode<MiWebRefreshTokenPayload>(authData.accessToken)
        return token.group_name;
    }
}
