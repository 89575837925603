import Entity from "./entities";
import {ResourceConfig} from "../../lib/types/resource";
import {SimpleTextMeta} from "../../lib/meta/simpleText/simpleText";
import {DefaultListView} from "../../lib/views/crud/list/default";
import {EntitiesDataProvider} from "./entitiesDataProvider";
import GenericSearch from "../genericSearch";
import {StaticSelectMeta} from "../../lib/meta/select/staticSelect";
import {DefaultShowView} from "../../lib/views/crud/show/default";

const FileTypes: {[key: string]: string} = {
    'mainClinical': 'Main Clinical',
    'other': 'Other',
    'sample': 'Sample'
};


export const EntitiesResource: ResourceConfig<Entity, GenericSearch> = {
    id: "entities",
    label: "Entities",
    dataProvider: new EntitiesDataProvider(),
    actions: [],
    edit: undefined,
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "description",
            label: "Description",
            meta: new SimpleTextMeta({})
        },
        {
            id: "fileType",
            label: "File Type",
            meta: new StaticSelectMeta({
                label: 'File Type',
                options: FileTypes,
                listValuer: (data) => FileTypes[(data as Entity).fileType]
            }),
        }
    ],
    list: {
        component: <DefaultListView
            fields={[{id: "id"}, {id: "name"}, {id: "description"}, {id: "fileType",}]}
            pageSize={20}
        />,
        id: "clinical_data",
        pageSize: 20,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}, {id: "fileType"}]
    },
    show: {
        component: <DefaultShowView previousPage={-1} id={"entities"} fields={[{id: "id"}, {id: "name"}, {id: "description"}, {id: "fileType"}]}/>,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}, {id: "fileType"}]
    },
    create: {
        pageSize: 5,
        fields: [
            {
                id: "name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {
                id: "description",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Description is required."
                    }
                },
            },
            {
                id: "fileType",
                validationOptions: {
                    required: {
                        value: true,
                        message: "File type is required."
                    }
                },
            }
        ]
    },
    description: "List of the entities",
    isDeletable: () => true,
    primaryKey: "id",
    groupId: "appManager"

}
