import axios from "axios";
import { BASE_URL } from "../../../App";
import {SourceFile, SourceFileFilter} from "./sourceFile";
import {PagedResponse} from "../../../types/pagedResponse";
import {BaseDataProvider} from "../../baseDataProvider";


export class SourceFileDataProvider extends BaseDataProvider<SourceFile, SourceFileFilter> {

    constructor(){
        super("source_files")
    }

    getUploadHeaders = async (): Promise<Record<string, string>> => {
        let headers = { 'Accept': 'multipart/form-data' };
        if (this.authProvider) {
            await this.authProvider.getAuthHeaders().then((authHeaders) => {
                headers = { ...headers, ...authHeaders }
            })
                .catch(() => {
                    this.authProvider?.setAuthData({isAuthenticated:()=>false})
                });
        }
        return Promise.resolve(headers)
    }

    list = async (page_size?: number, filter?: SourceFileFilter): Promise<SourceFile[]> => {
        return axios.get(`${BASE_URL}/source_files`, { headers: await this.getHeaders({"Accept": "application/json"}), params: { page_size, ...filter } }).then(r => {
            return r.data;
        });
    }

    pagedList = async <R extends PagedResponse<SourceFile>>(page_size?: number, page_token?: string, filter?: SourceFileFilter): Promise<R> => {
        return axios.get<R>(`${BASE_URL}/source_files`, { headers: await this.getHeaders({"Accept": "application/json"}), params: { page_size, page_token, ...filter } }).then(r => {
            return r.data;
        });
    }

    getById = async (id: string): Promise<SourceFile | undefined> => {
        return axios.get<SourceFile>(`${BASE_URL}/source_files/${id}`, { headers: await this.getHeaders({"Accept": "application/json"})}).then(r => {
            return r.data;
        });
    }

    postPreload = async (data: FormData) => {
        return axios.post(`${BASE_URL}/source_files/preload`, data, { headers: await this.getUploadHeaders() }).then(r => {
            return r.data
        });
    }

    delete = async (id: string) => {
        return axios.delete(`${BASE_URL}/source_files/${id}`, { headers: await this.getHeaders({"Accept": "application/json"})}).then(r => {
            return r.data
        });
    }
    hardDelete = async (id: string, hard_delete?: boolean): Promise<boolean> => {
        return axios.delete(`${BASE_URL}/source_files/${id}`, { headers: await this.getHeaders(), params: {hard_delete}})
            .then(r => { return r.data; });
    }
    download = async (id: string) =>{
        return axios.get(`${BASE_URL}/source_files/${id}/download`, { headers: await this.getHeaders({"Accept": "application/json"}),responseType:"blob" }).then(r => {
            return r.data
        });
    }
}
